.ion.ion-md-heart.mslsearch:before {
    font-size: 18px;
    color:#03a9f4;
    padding-left: 5px;
}

.dx-icon-box.mslsearch:before {
    font-size: 18px;
    color:#03a9f4;
    padding-left: 5px;
}

.dx-icon-card.mslsearch:before {
    font-size: 18px;
    color:#03a9f4;
    padding-left: 5px;
}

.ion.ion-md-document.mslsearch:before {
    font-size: 18px;
    color:#03a9f4;
    padding-left: 5px;
}