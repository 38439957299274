.TopToolbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
}

.TopToolbar .dx-placeholder {
    margin-left: 0px;
}

