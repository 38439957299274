#rladmin {
    display: flex;
  }
  
  #rlgrid {
    flex: 0 0 75%;
  }
  
  #rllist {
    flex: 1;
  }